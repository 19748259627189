.ant-menu.ant-menu-dark {
  .ant-menu-item {
    &:focus {
      outline: none;
      color: #fff;
    }

    &.ant-menu-item-selected:focus {
      background-color: #db7814;
    }
  }
}

.ant-drawer-content {
  background-color: #00152a;
}

.ant-drawer-header {
  background-color: #00152a;
  border-color: #19334d;
}

.ant-drawer-title {
  color: #fff;
}

.ant-drawer-body {
  padding: 0;
}
