.filepond--wrapper {
  max-width: 350px;
}

.filepond--root .filepond--panel-root {
  background-color: #edf0f4;
  height: 1em;
}

.filepond--credits {
  display: none;
}
