google-map {
  display: block;
  pointer-events: none;
  height: 100%;
}

.map-container {
  height: 100% !important;
  width: 100% !important;
}
