.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}

.ant-table .ant-table {
  margin: -16px !important;
}

.ant-tabs-tab {
  margin: 0 32px 0 0;
}

.ant-menu-submenu.ant-menu-submenu-placement-bottom {
  position: absolute;
}

.ant-avatar > img {
  object-fit: contain;
}

.ant-menu-dark .ant-dropdown-menu-item-divider {
  opacity: 0.3;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: none;
}
