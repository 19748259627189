@import "./assets/styles/breakpoints";
@import "./assets/styles/helpers";
@import "./assets/styles/navigation";
@import "./assets/styles/zorro";
@import "./assets/styles/filepond";
@import "./assets/styles/googlemaps";

html {
  font-size: 87.5%; /*14px*/
}

body {
  font-weight: 400;
  line-height: 1.75;
  color: #000;
}

p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 3rem 0 1.38rem;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 2.488rem;
}

h2 {
  font-size: 2.074rem;
}

h3 {
  font-size: 1.728rem;
  margin-top: 0;
}

h4 {
  font-size: 1.44rem;
}

h5 {
  font-size: 1.2rem;
}

small,
.text-small {
  font-size: 0.833rem;
}

img {
  height: auto;
  width: 100%;
}

i.xsmall {
  font-size: 8px;
}

i.small {
  font-size: 11px;
}

i.large {
  font-size: 24px;
}

i.xlarge {
  font-size: 42px;
}

.detail {
  display: flex;
  flex-direction: column;

  .detail-title {
    font-size: 14px;
  }

  .detail-content {
    font-size: 18px;
    font-weight: 500;
  }
}

.ant-input-textarea-show-count::after {
  position: absolute;
  right: 0;
}

// Small fix for icon button
a.ant-btn-icon-only {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
